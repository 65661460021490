import React from 'react'
import styled from 'styled-components'

import LessonsTable from './LessonsTable'

const LayoutWrapper = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.layout.height.header}px)`};
`

function LessonLayout({ course, lesson, children }) {
  return (
    <LayoutWrapper>
      <LessonsTable course={course} lesson={lesson}></LessonsTable>
      {children}
    </LayoutWrapper>
  )
}

export default LessonLayout
