import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { HeaderContext } from './HeaderProvider'
import { LayoutContext } from '../Layout'

const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 ${theme.spacers.normal};
    height: ${theme.layout.height.header}px;
    cursor: pointer;
  `}
`

const HamburgerMenu = styled.div`
  ${({ theme }) => css`
    width: 25px;
    height: 3px;
    background-color: ${theme.colors.neutral.gray800};
    border-radius: 5px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 3px;
      background-color: ${theme.colors.neutral.gray800};
      border-radius: 5px;
    }

    &::before {
      transform: translateY(-8px);
    }

    &::after {
      transform: translateY(8px);
    }
  `}
`

function CollapseButton() {
  const { toggleable } = useContext(LayoutContext)
  const { toggleLeftCollapseMenu } = useContext(HeaderContext)

  if (!toggleable) return null

  return (
    <Wrapper onClick={toggleLeftCollapseMenu}>
      <HamburgerMenu></HamburgerMenu>
    </Wrapper>
  )
}

export default CollapseButton
