import React, { useContext, useCallback } from 'react'
import styled from 'styled-components'

import theme from '../../theme/base'
import { HeaderContext } from './HeaderProvider'
import useDocumentScrollThrottled from '../../hooks/useDocumentScrollThrottled'
import BaseHeader from './BaseHeader'

const MINIMUM_SCROLL = theme.layout.height.header

const StyledHeader = styled(BaseHeader)`
  box-shadow: 0 2px 8px ${({ theme }) => theme.colors.neutral.gray300};
`

function DefaultHeader() {
  const { setIsHidden } = useContext(HeaderContext)

  const onScroll = useCallback(
    (data) => {
      const { previousScrollTop, currentScrollTop } = data
      const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

      if (!isMinimumScrolled) return

      const isScrolledDown = previousScrollTop < currentScrollTop

      setIsHidden(isScrolledDown && isMinimumScrolled)
    },
    [setIsHidden]
  )

  useDocumentScrollThrottled(
    (data) => {
      onScroll(data)
    },
    [onScroll]
  )

  return <StyledHeader></StyledHeader>
}

export default DefaultHeader
