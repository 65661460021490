import { useState, useCallback, useEffect } from 'react'
import { getFirebaseStorage } from '@babelcoder/gatsby-plugin-firebase'
import { useGlobalState } from '../components/GlobalStateProvider'

function useProfileImage() {
  const { user } = useGlobalState()
  const [profileImage, setProfileImage] = useState(null)
  const [isProfileImageLoaded, setIsProfileImageLoaded] = useState(false)

  const setProfileImageFromStorage = useCallback(async () => {
    if (!user?.photoURL) {
      setIsProfileImageLoaded(true)
      return
    }

    const firebaseStorage = await getFirebaseStorage()
    const url = await firebaseStorage.refFromURL(user.photoURL).getDownloadURL()

    setProfileImage(url)
    setIsProfileImageLoaded(true)
  }, [user, setProfileImage, setIsProfileImageLoaded])

  useEffect(() => {
    setProfileImageFromStorage()
  }, [setProfileImageFromStorage])

  return { profileImage, isProfileImageLoaded }
}

export default useProfileImage
