import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { Link } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import icons from '@babelcoder/gatsby-theme-courses/src/utils/icons';

const UnitHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacers.small};
    min-height: ${theme.layout.height.flashMessage}px;
    background-color: ${theme.colors.neutral.gray400};
    border-bottom: 1px solid ${theme.colors.neutral.gray300};
    cursor: pointer;
  `}
`;

const UnitHeaderIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.neutral.gray700};
    margin: 0 ${theme.spacers.small};
  `}
`;

const Lesson = styled(Link)`
  ${({ theme, $isActive, $forLive }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fontSizes.normal};
    color: ${theme.colors.neutral.gray1000};
    cursor: pointer;

    ${up('medium')} {
      font-size: ${theme.fontSizes.small};
    }

    ${$isActive &&
    css`
      background-color: ${theme.colors.neutral.white};
      color: ${theme.colors.main.primary};
      border: 1px solid ${theme.colors.main.primary};
      border-left: 0;
      font-family: ${theme.fontFamily.heading};
    `}

    ${$forLive &&
    css`
      background-color: ${theme.colors.accent.primaryLighten45};
    `}

    &:hover {
      background-color: ${theme.colors.neutral.gray300};
    }
  `}
`;

const Lessons = styled.div`
  &.lessons {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 0.5;
      transition: opacity 250ms;
    }

    &-exit {
      opacity: 0.5;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 250ms;
    }
  }
`;

const LessonIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacers.small};
  `}
`;

const LessonTitle = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding: 0 ${theme.spacers.small};
    border-left: 1px solid #dee3e2;
  `}
`;

export default function UnitItem({
  title,
  lessons,
  currentLesson,
  courseSlug,
}) {
  const [isCollapse, setIsCollapse] = useState(false);

  const toggleIsCollapse = useCallback(() => setIsCollapse(!isCollapse), [
    isCollapse,
    setIsCollapse,
  ]);

  return (
    <section key={title}>
      <UnitHeader onClick={toggleIsCollapse}>
        {title}
        <UnitHeaderIcon>
          {isCollapse ? (
            <FaChevronDown></FaChevronDown>
          ) : (
            <FaChevronUp></FaChevronUp>
          )}
        </UnitHeaderIcon>
      </UnitHeader>
      <CSSTransition
        in={!isCollapse}
        timeout={100}
        classNames="lessons"
        unmountOnExit
      >
        <Lessons>
          {lessons.map(({ title, slug, type, forLive }) => {
            const Icon = icons[type];

            return (
              <Lesson
                key={slug}
                to={`/courses/${courseSlug}/lessons/${slug}`}
                $isActive={currentLesson?.slug === slug}
                $forLive={forLive}
              >
                <LessonIcon>
                  <Icon></Icon>
                </LessonIcon>
                <LessonTitle>{title}</LessonTitle>
              </Lesson>
            );
          })}
        </Lessons>
      </CSSTransition>
    </section>
  );
}
