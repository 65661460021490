import React from 'react'
import styled, { css } from 'styled-components'

import PopupMessage from '@babelcoder/gatsby-theme-base/src/components/PopupMessage'
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'

const Main = styled.main`
  ${({ theme, hasFlashMessage }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-top: ${hasFlashMessage
      ? theme.layout.height.header + theme.layout.height.flashMessage
      : theme.layout.height.header}px;
  `}
`

function Content({ children }) {
  const { popupMessage, flashMessage } = useGlobalState()

  return (
    <Main hasFlashMessage={!!flashMessage}>
      {children}
      {popupMessage && (
        <PopupMessage
          type={popupMessage.type}
          title={popupMessage.title}
          message={popupMessage.message}
        ></PopupMessage>
      )}
    </Main>
  )
}

export default Content
