import React, { useContext } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

import { HeaderContext } from './HeaderProvider'
import Logo from './Logo'
import CollapseButton from './CollapseButton'

const Wrapper = styled.div`
  ${up('large')} {
    display: flex;
    align-items: center;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.gray300};
    width: 300px;
  }

  ${({ isRightCollapseMenu }) => !isRightCollapseMenu && `display: none`};
`

function BannerNav() {
  const { isRightCollapseMenu } = useContext(HeaderContext)

  return (
    <Wrapper isRightCollapseMenu={isRightCollapseMenu}>
      <CollapseButton></CollapseButton>
      <Logo></Logo>
    </Wrapper>
  )
}

export default BannerNav
