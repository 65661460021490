import React, { useContext } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { FaFacebookF, FaEnvelope } from 'react-icons/fa';

import { LayoutContext } from './Layout';
import useSiteMeta from '../hooks/useSiteMeta';
import { CookiesConsentContext } from '../components/CookiesConsentProvider';

const Wrapper = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray1500};
    border-top: ${theme.spacers.xxsmall} solid ${theme.colors.main.primary};
    text-align: center;
  `}
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Image = styled(Img)`
  ${({ theme }) => css`
    border-radius: 50%;
    background-color: ${theme.colors.neutral.white};
    box-shadow: 3px 3px 10px 1px ${theme.colors.accent.primaryLighten15};
    margin: ${theme.spacers.normal} 0;
  `}
`;

const Slogan = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.main.primary};
    font-size: ${theme.fontSizes.large};
  `}
`;

const Menu = styled.ul`
  padding: 0;
  margin: 1rem 0;
`;

const MenuItem = styled.li`
  ${({ theme }) => css`
    display: inline;
    padding: 0 1rem;

    & + * {
      border-left: 1px solid ${theme.colors.neutral.gray700};
    }

    a {
      color: ${theme.colors.neutral.gray700};
    }
  `}
`;

const EditPrivacyLink = styled.button`
  display: block;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.neutral.gray700};
  margin: ${({ theme }) => theme.spacers.normal} auto;
  cursor: pointer;
`;
const LineBreak = styled.div`
  ${({ theme }) => css`
    height: 1px;
    width: 50px;
    background-color: ${theme.colors.neutral.gray900};
    margin: ${theme.spacers.normal} 0 ${theme.spacers.normal} 50%;
    transform: translateX(-50%);
  `}
`;

const Copyright = styled.small`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.neutral.black};
    background-color: ${theme.colors.main.secondary};
    padding: ${theme.spacers.normal};
  `}
`;

const SocialLink = styled.a`
  ${({ theme, side }) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: ${theme.colors.main.primary};
    padding: ${theme.spacers.normal};
    border-radius: 50%;
    border: 1px solid ${theme.colors.neutral.gray300};

    ${side === 'left' &&
    css`
      left: -15px;
    `}

    ${side === 'right' &&
    css`
      right: -15px;
    `}
  `}
`;

const socialLinkIcon = css`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  color: ${({ theme }) => theme.colors.neutral.white};
`;

const FacebookIcon = styled(FaFacebookF)`
  ${socialLinkIcon}
`;

const EmailIcon = styled(FaEnvelope)`
  ${socialLinkIcon}
`;

const NAV_MENU = [
  { title: 'รู้จักเรา', href: '/about' },
  {
    title: 'ติดต่อเรา',
    href: 'mailto:babelcoder@gmail.com',
    originalTag: true,
  },
  { title: 'เงื่อนไขการใช้งาน', href: '/terms-of-service' },
  { title: 'นโยบายความเป็นส่วนตัว', href: '/privacy-policy' },
];

function Footer() {
  const { hasFooter } = useContext(LayoutContext);
  const { openSetting } = useContext(CookiesConsentContext);
  const { siteName, siteSlogan, siteEmail, facebook } = useSiteMeta();

  const { logo } = useStaticQuery(graphql`
    query {
      logo: imageSharp(original: { src: { regex: "/logo/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `);

  if (!hasFooter) return null;
  return (
    <Wrapper>
      <ImageWrapper>
        <Image fixed={logo.fixed}></Image>
        <SocialLink side="left" href={facebook}>
          <FacebookIcon></FacebookIcon>
        </SocialLink>
        <SocialLink side="right" href={`mailto:${siteEmail}`}>
          <EmailIcon></EmailIcon>
        </SocialLink>
      </ImageWrapper>
      <Slogan>{siteSlogan}</Slogan>
      <EditPrivacyLink onClick={openSetting}>
        แก้ไขความเป็นส่วนตัว
      </EditPrivacyLink>
      <LineBreak></LineBreak>
      <Menu>
        {NAV_MENU.map(({ title, href, originalTag = false }) => (
          <MenuItem key={href}>
            {originalTag ? (
              <a href={href}>{title}</a>
            ) : (
              <Link key={href} to={href}>
                {title}
              </Link>
            )}
          </MenuItem>
        ))}
      </Menu>
      <Copyright>
        Copyright © 2016 - 2024 {siteName}. All rights reserved.
      </Copyright>
    </Wrapper>
  );
}

export default Footer;
