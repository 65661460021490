import { createGlobalStyle, css } from 'styled-components'
import { up } from 'styled-breakpoints'

import globalCSS from '../theme/global'

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    ${globalCSS}

    html {
      font-size: ${theme.fontSizes.base.small};
      box-sizing: border-box;

      ${up('medium')} {
        font-size: ${theme.fontSizes.base.medium};
      }

      ${up('large')} {
        font-size: ${theme.fontSizes.base.large};
      }
    }

    html,
    body,
    #___gatsby,
    #gatsby-focus-wrapper {
      height: 100%;
    }

    *,
    *:before,
    *:after {
      box-sizing: inherit;
      -webkit-tap-highlight-color: transparent;
    }

    ::selection {
      background-color: ${theme.colors.main.secondary};
      color: ${theme.colors.neutral.white};
    }

    body {
      text-align: left;
      font-family: ${theme.fontFamily.normal};
      color: ${theme.colors.neutral.gray1000};
      font-size: ${theme.fontSizes.normal};
      font-weight: normal;
      line-height: 2;
      margin: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 1px solid ${theme.colors.main.primary};
      -webkit-text-fill-color: ${theme.colors.accent.primaryDarken15};
      -webkit-box-shadow: 0 0 0px 1000px ${theme.colors.accent.primaryLighten30}
        inset;
    }

    input[type] {
      appearance: none;
    }

    a {
      text-decoration: none;
    }

    img {
      width: 100%;
    }

    ${['large', 'medium', 'normal', 'small', 'xsmall', 'xxsmall'].map(
      (size, index) => css`
        h${index + 1} {
          margin: 0;
          font-family: ${theme.fontFamily.heading};
          font-size: ${theme.fontSizes[size]};
          font-weight: normal;
        }
      `
    )}
  `}
`

export default GlobalStyle
