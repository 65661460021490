import React, { useCallback } from 'react'
import styled, { css, keyframes } from 'styled-components'
import {
  FaSkull,
  FaExclamationCircle,
  FaCheckCircle,
  FaInfoCircle,
  FaTimes,
} from 'react-icons/fa'

import theme from '../theme/base'
import useTimeout from '../hooks/useTimeout'
import { useGlobalDispatch } from './GlobalStateProvider'
import { actions } from '../store/reducer'

const MAX_WIDTH = '250px'

const slideInRight = keyframes`
  0% {
    transform: translateX(${MAX_WIDTH});
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const StyledPopupMessage = styled.div`
  ${({ theme }) => css`
    display: grid;
    position: fixed;
    right: ${theme.spacers.normal};
    bottom: ${theme.spacers.normal};
    grid-template:
      'icon title actions' auto
      'icon desc actions' 1fr
      / auto 1fr auto;
    justify-items: center;
    align-items: center;
    gap: calc(${theme.spacers.normal} / 2);
    box-shadow: ${`0 2px 8px ${theme.colors.gray600}`};
    background: ${theme.colors.white};
    padding: ${theme.spacers.normal} 0;
    border-radius: ${theme.round.normal};
    z-index: ${theme.zIndex.popup};
    animation: ${slideInRight} 0.25s ease-in-out;
  `}
`

const getIcon = (type) => {
  switch (type) {
    case 'success':
      return FaCheckCircle
    case 'warning':
      return FaExclamationCircle
    case 'danger':
      return FaSkull
    case 'info':
    default:
      return FaInfoCircle
  }
}

const IconWrapper = styled.div`
  ${({ theme }) => css`
    grid-area: icon;
    font-size: ${theme.fontSizes.xlarge};
    padding: 0 ${theme.spacers.normal};
  `}
`

const Title = styled.span`
  ${({ theme }) => css`
    grid-area: title;
    justify-self: start;
    margin-right: ${theme.spacers.normal};
  `}
`

const Message = styled.p`
  ${({ theme }) => css`
    grid-area: desc;
    justify-self: start;
    max-width: ${MAX_WIDTH};
    margin: 0;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.gray900};
  `}
`

const Actions = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    grid-area: actions;
    justify-self: stretch;
    align-items: center;
    padding: 0 ${theme.spacers.normal};
    border-left: 1px solid ${theme.colors.gray600};
    cursor: pointer;
  `}
`

const CloseButton = styled(FaTimes)`
  ${({ theme }) => `
    margin-bottom: calc(${theme.spacers.normal} / 2);
    color: ${theme.colors.danger};
  `}
`

const PopupMessage = ({
  type,
  title,
  message,
  className,
  onClose,
  timeout = 3000,
}) => {
  const dispatch = useGlobalDispatch()
  const Icon = getIcon(type)

  const clearPopupMessage = useCallback(
    () => dispatch({ type: actions.clearPopupMessage }),
    [dispatch]
  )

  useTimeout(clearPopupMessage, timeout)

  return (
    <StyledPopupMessage className={className}>
      <IconWrapper>
        <Icon color={theme.colors.main[type]}></Icon>
      </IconWrapper>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <Actions onClick={clearPopupMessage}>
        <CloseButton></CloseButton>
        <span>Close</span>
      </Actions>
    </StyledPopupMessage>
  )
}

export default PopupMessage
