import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import { globalHistory } from '@reach/router'

export const HeaderContext = createContext()

function HeaderProvider({ children }) {
  const [isRightCollapseMenu, setIsRightCollapseMenu] = useState(true)
  const [isLeftCollapseMenu, setIsLeftCollapseMenu] = useState(false)
  const [isHidden, setIsHidden] = useState(false)

  const toggleRightCollapseMenu = useCallback(
    () => setIsRightCollapseMenu(!isRightCollapseMenu),
    [isRightCollapseMenu, setIsRightCollapseMenu]
  )

  const toggleLeftCollapseMenu = useCallback(
    () => setIsLeftCollapseMenu(!isLeftCollapseMenu),
    [isLeftCollapseMenu, setIsLeftCollapseMenu]
  )

  const contextValue = useMemo(
    () => ({
      isHidden,
      isRightCollapseMenu,
      isLeftCollapseMenu,
      setIsHidden,
      setIsRightCollapseMenu,
      setIsLeftCollapseMenu,
      toggleRightCollapseMenu,
      toggleLeftCollapseMenu,
    }),
    [
      isHidden,
      isRightCollapseMenu,
      isLeftCollapseMenu,
      setIsHidden,
      setIsRightCollapseMenu,
      setIsLeftCollapseMenu,
      toggleRightCollapseMenu,
      toggleLeftCollapseMenu,
    ]
  )

  useEffect(() => {
    globalHistory.listen(() => {
      setIsHidden(false)
    })
  }, [globalHistory])

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider
