const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

let firebaseInstance
let firebaseAuth
let firebaseFirestore
let firebaseStorage

export async function getFirebase() {
  if (firebaseInstance) return firebaseInstance

  const firebaseApp = import(
    'firebase/app' /* webpackChunkName: "firebase-app" */
  )

  const firebase = await firebaseApp

  if (firebase.apps.length > 0) return firebase.app()

  firebase.initializeApp(config)
  firebaseInstance = firebase

  return firebase
}

export async function getFirebaseAuth() {
  if (firebaseAuth) return firebaseAuth

  const firebaseAuthLazy = import(
    'firebase/auth' /* webpackChunkName: "firebase-auth" */
  )

  await firebaseAuthLazy

  const firebase = await getFirebase()
  firebaseAuth = firebase.auth()

  return firebaseAuth
}

export async function getFirebaseFirestore() {
  if (firebaseFirestore) return firebaseFirestore

  const firebaseFirestoreLazy = import(
    'firebase/firestore' /* webpackChunkName: "firebase-firestore" */
  )

  await firebaseFirestoreLazy

  const firebase = await getFirebase()
  firebaseFirestore = firebase.firestore()

  return firebaseFirestore
}

export async function getFirebaseStorage() {
  if (firebaseStorage) return firebaseStorage

  const firebaseStorageLazy = import(
    'firebase/storage' /* webpackChunkName: "firebase-storage" */
  )

  await firebaseStorageLazy

  const firebase = await getFirebase()
  firebaseStorage = firebase.storage()

  return firebaseStorage
}
