import React, { useContext } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { down } from 'styled-breakpoints'

import { HeaderContext } from '@babelcoder/gatsby-theme-base/src/components/header/HeaderProvider'
import FlashMessage from '@babelcoder/gatsby-theme-base/src/components/FlashMessage'
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import AuthChecker from './AuthChecker'
import Menu from './Menu'
import RightCollapseMenu from './RightCollapseMenu'
import NavList from './NavList'
import { LayoutContext } from '../Layout'

const fadeInAnimation = keyframes`
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
`

const StyledHeader = styled.header`
  ${({ theme, isHidden, isRightCollapseMenu }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${theme.layout.height.header}px;
    background-color: ${theme.colors.neutral.white};
    z-index: ${theme.zIndex.navbar};
    transform: translateY(${isHidden ? `-${theme.layout.height.header}px` : 0});
    transition: transform 0.3s ease;

    ${down('medium')} {
      ${!isRightCollapseMenu &&
      css`
        right: 0;
        bottom: 0;
        background-color: ${theme.colors.main.primary};
        height: 100%;
        transform: translateY(0);
        z-index: ${theme.zIndex.topmost};
        animation: ${fadeInAnimation} 0.3s ease-in-out;
      `}
    }
  `}
`

const StyledFlashMessage = styled(FlashMessage)`
  transition: transform 0.25s, margin-right 0.25s;

  ${({ $isShrink }) =>
    $isShrink &&
    css`
      transform: translateX(300px);
      margin-right: 300px;

      ${down('medium')} {
        transform: none;
        margin-right: 0;
      }
    `}
`

function DefaultHeader({ className }) {
  const { flashMessage } = useGlobalState()
  const { toggleable } = useContext(LayoutContext)
  const { isRightCollapseMenu, isLeftCollapseMenu, isHidden } = useContext(
    HeaderContext
  )

  return (
    <>
      <AuthChecker></AuthChecker>
      <StyledHeader
        className={className}
        isRightCollapseMenu={isRightCollapseMenu}
        isHidden={isHidden}
      >
        <Menu>
          <NavList></NavList>
          <RightCollapseMenu></RightCollapseMenu>
        </Menu>
        {flashMessage && (
          <StyledFlashMessage
            $isShrink={toggleable && !isLeftCollapseMenu}
            message={flashMessage.message}
            type={flashMessage.type}
            actionText={flashMessage.actionText}
            onAction={flashMessage.onAction}
          ></StyledFlashMessage>
        )}
      </StyledHeader>
    </>
  )
}

export default DefaultHeader
