import React from 'react'
import ReactDOM from 'react-dom'

const canUseDOM = process.browser
const getParent = () => document.body

class Portal extends React.Component {
  componentWillUnmount() {
    if (this.defaultNode) {
      getParent().removeChild(this.defaultNode)
    }

    this.defaultNode = null
  }

  render() {
    if (!canUseDOM) return null

    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement('div')
      getParent().appendChild(this.defaultNode)
    }

    return ReactDOM.createPortal(
      this.props.children,
      this.props.node || this.defaultNode,
    )
  }
}

export default Portal
