import React from 'react'

import Layout from '@babelcoder/gatsby-theme-base/src/components/Layout'
import GlobalStateProvider from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { isLessonPath } from '@babelcoder/gatsby-theme-courses/src/utils/path'

export const wrapPageElement = ({ element, props }) => {
  const {
    type: { layout },
  } = element

  switch (true) {
    case isLessonPath(props.uri):
      return
    default:
      return (
        <Layout {...props} layout={layout}>
          {element}
        </Layout>
      )
  }
}

export const wrapRootElement = ({ element }) => {
  return <GlobalStateProvider>{element}</GlobalStateProvider>
}
