function toSingleSlash(str) {
  // Safari does not support lookahead / lookbehind
  // return str.replace(/(?<!http(s)?:)(\/\/+)/g, '/')
  const withHttps = str.startsWith('https://')

  if (!withHttps) return str.replace(/\/\/+/g, '/')

  const [, replacedStr] = str.split('https://')

  return `https://${replacedStr.replace(/\/\/+/g, '/')}`
}

module.exports = toSingleSlash
