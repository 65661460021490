import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'

import { HeaderContext } from '@babelcoder/gatsby-theme-base/src/components/header/HeaderProvider'
import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import ProfileMenu from './ProfileMenu'
import MainNav from './MainNav'
import SecondaryNav from './SecondaryNav'

const NavListWrapper = styled.div`
  display: flex;
  flex: 1;

  ${down('medium')} {
    ${({ isRightCollapseMenu }) =>
      isRightCollapseMenu
        ? css`
            display: none;
          `
        : css`
            flex-direction: column;
            justify-content: center;
          `};
  }
`

function NavList() {
  const { user, isAuthLoaded } = useGlobalState()
  const { isRightCollapseMenu, setIsRightCollapseMenu } = useContext(
    HeaderContext
  )

  return (
    <NavListWrapper isRightCollapseMenu={isRightCollapseMenu}>
      <MainNav></MainNav>
      {user ? (
        <ProfileMenu
          setIsRightCollapseMenu={setIsRightCollapseMenu}
        ></ProfileMenu>
      ) : (
        isAuthLoaded && <SecondaryNav></SecondaryNav>
      )}
    </NavListWrapper>
  )
}

export default NavList
