import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { FaTimes } from 'react-icons/fa'

import { useGlobalDispatch } from './GlobalStateProvider'
import { actions } from '../store/reducer'

const Wrapper = styled.div`
  ${({ theme, type }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${theme.layout.height.flashMessage}px;
    padding: 0 ${theme.spacers.normal};
    color: ${theme.colors.accent[`${type}Darken15`]};
    background-color: ${theme.colors.accent[`${type}Lighten30`]};
  `}
`

const Message = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
`

const ActionButton = styled.button`
  ${({ theme, type }) => css`
    margin-left: auto;
    margin-right: ${theme.spacers.xsmall};
    color: ${theme.colors.accent[`${type}Lighten30`]};
    background-color: ${theme.colors.accent[`${type}Darken15`]};
    padding: ${theme.spacers.xxsmall};
    border: none;
    border-radius: ${theme.round.normal};
    outline: none;
    cursor: pointer;
  `}
`

function FlashMessage({ message, type, actionText, onAction, className }) {
  const dispatch = useGlobalDispatch()

  const closeFlashMessage = useCallback(
    () => dispatch({ type: actions.clearFlashMessage }),
    [dispatch]
  )

  return (
    <Wrapper type={type} className={className}>
      <Message>{message}</Message>
      {actionText && (
        <ActionButton type={type} onClick={onAction}>
          {actionText}
        </ActionButton>
      )}
      <FaTimes onClick={closeFlashMessage}></FaTimes>
    </Wrapper>
  )
}

export default FlashMessage
