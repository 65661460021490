import { useEffect } from 'react'

function useTimeout(fn, timeout) {
  useEffect(() => {
    const timer = setTimeout(fn, timeout)

    return () => clearTimeout(timer)
  }, [fn, timeout])
}

export default useTimeout
