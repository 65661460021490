import React, { useState, useContext, useCallback } from 'react'
import styled, { css } from 'styled-components'

import theme from '../../theme/base'
import { HeaderContext } from './HeaderProvider'
import useDocumentScrollThrottled from '../../hooks/useDocumentScrollThrottled'
import BaseHeader from './BaseHeader'

const MINIMUM_SCROLL = theme.layout.height.header

const StyledHeader = styled(BaseHeader)`
  ${({ theme, isShadowEnabled }) =>
    isShadowEnabled &&
    css`
      box-shadow: 0 2px 8px ${theme.colors.neutral.gray300};
    `};
`

function DisabledShadowOnTopHeader() {
  const { setIsHidden } = useContext(HeaderContext)
  const [isShadowEnabled, setIsShadowEnabled] = useState(false)

  const onScroll = useCallback(
    (data) => {
      const { previousScrollTop, currentScrollTop } = data
      const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL

      if (!isMinimumScrolled) {
        setIsShadowEnabled(() => false)
        return
      }

      const isScrolledDown = previousScrollTop < currentScrollTop
      const result = isScrolledDown && isMinimumScrolled

      setIsHidden(result)
      setIsShadowEnabled(!result)
    },
    [setIsHidden, setIsShadowEnabled]
  )

  useDocumentScrollThrottled(
    (data) => {
      onScroll(data)
    },
    [onScroll]
  )

  return <StyledHeader isShadowEnabled={isShadowEnabled}></StyledHeader>
}

export default DisabledShadowOnTopHeader
