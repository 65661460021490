import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

import NavItem from './NavItem'

const MainNavWrapper = styled.div`
  display: flex;
  flex: 1;

  ${down('medium')} {
    order: 1;
    flex: initial;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: fit-content;
    overflow-y: scroll;
  }
`

const LINKS = [
  { title: 'คอร์ส', path: '/courses' },
  { title: 'บทความ', path: '/blog' },
  { title: 'Tips', path: '/tips' },
  { title: 'Weekly', path: '/weekly' },
]

function MainNav() {
  return (
    <MainNavWrapper>
      {LINKS.map(({ title, path }, index) => (
        <NavItem key={path} to={path} index={index + 1}>
          {title}
        </NavItem>
      ))}
    </MainNavWrapper>
  )
}

export default MainNav
