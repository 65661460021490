import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import Modal from '@babelcoder/gatsby-theme-base/src/components/Modal';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { CookiesConsentContext } from './CookiesConsentProvider';
import { up } from 'styled-breakpoints';

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LineBreak = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.gray200};
  width: 100%;
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const SectionList = styled.div`
  width: 100%;

  ${up('small')} {
    width: 500px;
  }

  ${up('medium')} {
    width: 600px;
  }
`;

const Section = styled.section`
  & + * {
    border-top: 1px solid ${({ theme }) => theme.colors.neutral.gray200};
  }
`;

const SectionHeader = styled.header``;

const SectionTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.normal};

  & > * + * {
    margin-left: ${({ theme }) => theme.spacers.normal};
  }
`;

const SectionDesc = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const CookiesName = styled.p`
  cursor: pointer;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: auto;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${({ theme }) => theme.colors.main.primary};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${({ theme }) => theme.colors.main.primary};
  }

  &:checked + span:before {
    transform: translateX(26px);
  }
`;

const SwitchSlidder = styled.span`
  position: absolute;
  ${({ essential }) => !essential && `cursor: pointer`};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
  }
`;

const CookiesListWrapper = styled.dl`
  display: grid;
  grid-template-columns: minmax(100px, auto) 1fr;
  background-color: ${({ theme }) => theme.colors.neutral.gray200};
`;

const CookiesListTerm = styled.dt`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  padding: ${({ theme }) => theme.spacers.xxsmall};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const CookiesListDefinition = styled.dd`
  padding: ${({ theme }) => theme.spacers.xxsmall};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const ModalFooter = styled.footer`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const AcceptAllButton = styled.button`
  ${({ theme }) => css`
    display: block;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    color: ${theme.colors.neutral.white};
    border-radius: 2rem;
    border: none;
    outline: none;
    transition: background-color 0.25s;
    background-color: ${theme.colors.main.primary};
    cursor: pointer;
  `}
`;

const AcceptMyChoices = styled.button`
  display: block;
  border: none;
  outline: none;
  text-decoration: underline;
  background-color: transparent;
  margin-left: ${({ theme }) => theme.spacers.normal};
  cursor: pointer;
`;

const ToggleSwitch = ({ consent: currentConsent, essential }) => {
  const { changeConsent, consent } = useContext(CookiesConsentContext);

  return (
    <Switch>
      <SwitchInput
        type="checkbox"
        disabled={essential}
        defaultChecked={
          consent === 'base' ||
          consent[currentConsent] === undefined ||
          consent[currentConsent]
        }
        onChange={(e) => changeConsent(currentConsent, e.target.checked)}
      />
      <SwitchSlidder
        className="slider round"
        essential={essential}
      ></SwitchSlidder>
    </Switch>
  );
};

const CookiesList = ({ cookie }) => {
  return (
    <CookiesListWrapper>
      <CookiesListTerm>ชื่อ</CookiesListTerm>
      <CookiesListDefinition>{cookie.name}</CookiesListDefinition>
      <CookiesListTerm>โดเมน</CookiesListTerm>
      <CookiesListDefinition>{cookie.domain}</CookiesListDefinition>
      <CookiesListTerm>ระยะเวลาจัดเก็บ</CookiesListTerm>
      <CookiesListDefinition>{cookie.duration}</CookiesListDefinition>
      <CookiesListTerm>การจัดเก็บ</CookiesListTerm>
      <CookiesListDefinition>{cookie.category}</CookiesListDefinition>
      <CookiesListTerm>คำอธิบาย</CookiesListTerm>
      <CookiesListDefinition>{cookie.desc}</CookiesListDefinition>
    </CookiesListWrapper>
  );
};

const CookiesConsentSection = ({ cookie }) => {
  const [isShown, setIsShown] = useState(false);

  const toggleIsShown = () => {
    setIsShown(!isShown);
  };

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>
          {isShown ? (
            <FaAngleDown onClick={toggleIsShown}></FaAngleDown>
          ) : (
            <FaAngleUp onClick={toggleIsShown}></FaAngleUp>
          )}
          <CookiesName onClick={toggleIsShown}>{cookie.title}</CookiesName>
          <ToggleSwitch
            consent={cookie.key}
            essential={cookie.essential}
          ></ToggleSwitch>
        </SectionTitle>
      </SectionHeader>
      {isShown && (
        <>
          <SectionDesc>{cookie.desc}</SectionDesc>
          {cookie.cookies.map((cookie) => (
            <CookiesList key={cookie.name} cookie={cookie}></CookiesList>
          ))}
        </>
      )}
    </Section>
  );
};

const cookiesSections = [
  {
    title: 'การจัดเก็บพื้นฐานที่จำเป็นอย่างยิ่ง',
    essential: true,
    key: 'base',
    desc:
      'เพื่อให้การทำงานหลักของเว็บไซต์เป็นไปอย่างถูกต้องและปลอดภัย หากปราศจากการจัดเก็บประเภทนี้บางส่วนของเว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม การจัดเก็บประเภทดังกล่าวจึงถูกเปิดใช้งานโดยอัตโนมัติและไม่สามารถปิดการใช้งานได้',
    cookies: [
      {
        name: 'fbase_key',
        domain: 'https://www.babelcoder.com',
        duration: 'ไม่กำหนด',
        category: 'IndexedDB',
        desc:
          'ใช้เพื่อระบุผู้ใช้งานได้อย่างถูกต้องและการจัดเก็บข้อมูลที่สัมพันธ์กับผู้ใช้งานนั้น ๆ',
      },
    ],
  },
  {
    title: 'คุกกี้ประสิทธิภาพและสถิติ',
    key: 'stat',
    desc:
      'เพื่อให้เราสามารถปรับปรุงประสิทธิภาพการใช้งานได้ดียิ่งขึ้น เราจึงใช้คุกกี้ประเภทนี้เพื่อเข้าถึงข้อมูลเชิงลึกเกี่ยวกับรูปแบบการใช้งานเว็บไซต์ของคุณ เช่น หน้าเพจที่ท่านเข้าชม เป็นต้น',
    cookies: [
      {
        name: '_ga',
        domain: '.babelcoder.com',
        duration: '2 ปี',
        category: 'คุกกี้',
        desc:
          'คุกกี้นี้ถูกใช้โดย Google Analytics เพื่อใช้แยกความแตกต่างระหว่างผู้ใช้งานในระบบ',
      },
      {
        name: '_gid',
        domain: '.babelcoder.com',
        duration: '24 ชั่วโมง',
        category: 'คุกกี้',
        desc:
          'คุกกี้นี้ถูกใช้โดย Google Analytics เพื่อช่วยในการวิเคราะห์ผลลัพธ์การดำเนินการของผู้ใช้งานในระบบด้วยการแยกความแตกต่างระหว่างผู้ใช้งาน',
      },
      {
        name: '_gat',
        domain: '.babelcoder.com',
        duration: '1 นาที',
        category: 'คุกกี้',
        desc:
          'คุกกี้นี้ถูกใช้โดย Google Analytics เพื่อวัดอัตราการร้องขอข้อมูล',
      },
    ],
  },
  {
    title: 'คุกกี้การกำหนดเป้าหมายเพื่อโฆษณา',
    key: 'ads',
    desc:
      'คุกกี้ประเภทนี้ใช้ติดตามพฤติกรรมการใช้งานเพื่อนำเสนอโฆษณาที่เหมาะสมต่อผู้ใช้แต่ละรายด้วยการเผยแพร่สู่แพลตฟอร์มของผู้ให้บริการด้านโฆษณา เช่น Facebook หากคุณไม่อนุญาตการเข้าถึงคุกกี้เหล่านี้ คุณจะเห็นโฆษณาที่กำหนดเป้าหมายน้อยลง',
    cookies: [
      {
        name: '_fbp',
        domain: '.babelcoder.com',
        duration: '90 วัน',
        category: 'คุกกี้',
        desc: 'คุกกี้นี้ถูกใช้โดย Facebook เพื่อการวิเคราะห์หน้าเพจและการโฆษณา',
      },
    ],
  },
];

const CookiesConsentSetting = () => {
  const {
    closeSetting,
    isDialogShown,
    acceptAll,
    acceptMyChoices,
  } = useContext(CookiesConsentContext);

  if (!isDialogShown) return null;

  return (
    <Modal onClose={closeSetting}>
      <ModalWrapper>
        <Title>การตั้งค่าความเป็นส่วนตัว</Title>
        <LineBreak></LineBreak>
        <SectionList>
          {cookiesSections.map((cookie) => (
            <CookiesConsentSection
              key={cookie.key}
              cookie={cookie}
            ></CookiesConsentSection>
          ))}
        </SectionList>
        <ModalFooter>
          <AcceptAllButton
            onClick={() => {
              acceptAll();
              closeSetting();
            }}
          >
            ยอมรับทั้งหมด
          </AcceptAllButton>
          <AcceptMyChoices
            onClick={() => {
              acceptMyChoices();
              closeSetting();
            }}
          >
            ยอมรับตัวเลือกของฉัน
          </AcceptMyChoices>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
};

export default CookiesConsentSetting;
