export const initialState = {
  isAuthLoaded: false,
  user: null,
  fullName: null,
  flashMessage: null,
  popupMessage: null,
};

export const actions = {
  setCurrentUser: 'SET_CURRENT_USER',
  logout: 'LOGOUT',
  showFlashMessage: 'SHOW_FLASH_MESSAGE',
  clearFlashMessage: 'CLEAR_FLASH_MESSAGE',
  showPopupMessage: 'SHOW_POPUP_MESSAGE',
  clearPopupMessage: 'CLEAR_POPUP_MESSAGE',
  setFullName: 'SET_FULL_NAME',
};

function reducer(state, action) {
  switch (action.type) {
    case actions.setCurrentUser:
    case actions.logout:
      return { ...state, user: action.user, isAuthLoaded: true };
    case actions.showFlashMessage:
      return { ...state, flashMessage: action.flashMessage };
    case actions.clearFlashMessage:
      return { ...state, flashMessage: null };
    case actions.showPopupMessage:
      return { ...state, popupMessage: action.popupMessage };
    case actions.clearPopupMessage:
      return { ...state, popupMessage: null };
    case actions.setFullName:
      return { ...state, fullName: action.fullName };
    default:
      return state;
  }
}

export default reducer;
