import React, { createContext, useMemo } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import GlobalStyle from './GlobalStyle';
import { light } from '../theme';
import HeaderProvider from './header/HeaderProvider';
import Footer from './Footer';
import Header from './Header';
import Content from './Content';
import CookiesConsentProvider from './CookiesConsentProvider';
import CookiesConsentDialog from './CookiesConsentDialog';
import CookiesConsentSetting from './CookiesConsentSetting';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const LayoutContext = createContext();

function Layout({ layout = {}, children }) {
  const contextValue = useMemo(
    () => ({
      headerType: 'default',
      hasFooter: true,
      ...layout,
    }),
    [layout]
  );

  return (
    <ThemeProvider theme={light}>
      <CookiesConsentProvider>
        <HeaderProvider>
          <GlobalStyle></GlobalStyle>
          <LayoutContext.Provider value={contextValue}>
            <LayoutWrapper>
              <Header></Header>
              <Content>{children}</Content>
              <Footer></Footer>
              <CookiesConsentDialog></CookiesConsentDialog>
              <CookiesConsentSetting></CookiesConsentSetting>
            </LayoutWrapper>
          </LayoutContext.Provider>
        </HeaderProvider>
      </CookiesConsentProvider>
    </ThemeProvider>
  );
}

export default Layout;
