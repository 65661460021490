import { graphql, useStaticQuery } from 'gatsby'

function useSiteMeta() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
          siteUrl
          siteEmail
          siteSlogan
          siteSloganDesc
          facebook
          locale
          facebookAppID
        }
      }
      logo: imageSharp(original: { src: { regex: "/logo/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      authorLogo: imageSharp(original: { src: { regex: "/author/" } }) {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `)

  return siteMetadata
}

export default useSiteMeta
