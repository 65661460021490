import React, { useContext, useState, useEffect, useCallback } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { up, down } from 'styled-breakpoints'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

import { HeaderContext } from '@babelcoder/gatsby-theme-base/src/components/header/HeaderProvider'

const navItemAnimation = keyframes`
  from {
    opacity: 0%;
    font-size: 0;
  }

  to {

    opacity: 100%;
  }
`

const MainNavItem = styled.div`
  ${({ theme, isRightCollapseMenu, index }) => css`
    color: ${theme.colors.neutral.gray1200};

    ${down('medium')} {
      font-size: ${theme.fontSizes.xlarge};
      color: ${theme.colors.neutral.white};

      ${!isRightCollapseMenu &&
      css`
        animation: ${navItemAnimation} ${index * 0.125}s linear;
      `}
    }

    ${up('large')} {
      display: flex;
      position: relative;
      align-items: center;
      margin: 0 ${theme.spacers.normal};
    }
  `}
`

const SecondaryNavItem = styled(MainNavItem)`
  ${({ theme }) => css`
    ${down('medium')} {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: ${theme.fontSizes.large};
      background-color: ${theme.colors.accent.primaryDarken15};

      & + &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 50%;
        border-left: 1px solid ${theme.colors.neutral.gray600};
      }
    }
  `}
`

const NavLink = styled.span`
  ${({ theme, $highlight, $isActive }) => css`
    position: relative;
    color: ${theme.colors.neutral.white};
    width: 100%;
    text-align: center;
    cursor: pointer;

    ${up('large')} {
      color: ${theme.colors.neutral.gray1200};

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        transform: translateX(-50%);
        transition: width 0.15s ease-in-out;
      }

      &:hover {
        &::after {
          width: ${$highlight ? 0 : '100%'};
          height: 5px;
          background-color: ${theme.colors.main.secondary};
          border-radius: ${theme.round.xlarge};
        }
      }

      ${
        $isActive &&
        css`
          &::after {
            width: ${$highlight ? 0 : '100%'};
            height: 5px;
            background-color: ${theme.colors.main.primary};
            border-radius: ${theme.round.xlarge};
          }
        `
      }

      ${
        $highlight &&
        css`
          color: ${theme.colors.main.primary};
          transition: color 0.25s, background-color 0.25s;
          padding: ${theme.spacers.xxsmall} ${theme.spacers.normal};
          border: 1px solid ${theme.colors.main.primary};
          border-radius: 1.5rem;

          &:hover {
            color: ${theme.colors.neutral.white};
            background-color: ${theme.colors.main.secondary};
          }
        `
      }

      ${
        $highlight &&
        $isActive &&
        css`
          color: ${theme.colors.neutral.white};
          background-color: ${theme.colors.main.primary};
        `
      }
    }
  `}
`

function NavItem({ secondary = false, to, $highlight, children, index }) {
  const location = useLocation()
  const { isRightCollapseMenu, setIsRightCollapseMenu } = useContext(
    HeaderContext
  )
  const [isActive, setIsActive] = useState(false)
  const Item = secondary ? SecondaryNavItem : MainNavItem

  useEffect(() => {
    setIsActive(location.pathname.startsWith(to))
  }, [to, setIsActive, location.pathname])

  const navigateTo = useCallback(() => {
    navigate(to)
    setIsRightCollapseMenu(true)
  }, [to, setIsRightCollapseMenu])

  return (
    <Item
      isRightCollapseMenu={isRightCollapseMenu}
      index={index}
      onClick={navigateTo}
    >
      <NavLink $highlight={$highlight} $isActive={isActive}>
        {children}
      </NavLink>
    </Item>
  )
}

export default NavItem
