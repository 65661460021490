import React from 'react'

import Layout from '@babelcoder/gatsby-theme-base/src/components/Layout'
import LessonLayout from './src/components/courses/LessonLayout'
import { isLessonPath } from './src/utils/path'

export const wrapPageElement = ({ element, props }) => {
  const {
    type: { layout },
  } = element

  if (!isLessonPath(props.uri)) return

  return (
    <Layout {...props} layout={layout}>
      <LessonLayout
        course={props.data.course}
        lesson={props.pageContext.lesson}
      >
        {element}
      </LessonLayout>
    </Layout>
  )
}
