import React, { useReducer, createContext, useMemo, useContext } from 'react'

import reducer, { initialState } from '../store/reducer'

export const GlobalStateContext = createContext()

export function useGlobalState() {
  const { state } = useContext(GlobalStateContext)

  return state
}

export function useGlobalDispatch() {
  const { dispatch } = useContext(GlobalStateContext)

  return dispatch
}

function GlobalStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateProvider
