import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

const Wrapper = styled(Img)`
  ${({ theme }) => css`
    border-radius: 50%;
    box-shadow: 0 0 3px 2px ${theme.colors.neutral.gray400};
    margin-right: ${theme.spacers.normal};
  `}
`

function LogoImage() {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: imageSharp(original: { src: { regex: "/logo/" } }) {
        fixed(width: 40, height: 40) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `)

  return <Wrapper fixed={logo.fixed}></Wrapper>
}

export default LogoImage
