import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'
import { FaChevronDown } from 'react-icons/fa'
import { getFirebaseAuth } from '@babelcoder/gatsby-plugin-firebase'
import { CSSTransition } from 'react-transition-group'
import { down } from 'styled-breakpoints'

import { useGlobalState, useGlobalDispatch } from '../GlobalStateProvider'
import NoUserImage from '@assets/images/no-user.svg'
import { actions } from '../../store/reducer'
import useProfileImage from '../../hooks/useProfileImage'

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.spacers.normal};

    ${down('medium')} {
      order: 0;
      margin: ${theme.spacers.large} 0;
    }
  `}
`

const Display = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: ${theme.layout.height.header}px;

    & > * + * {
      margin-left: ${theme.spacers.xsmall};
    }

    ${down('medium')} {
      flex-direction: column;
      width: max-content;
      height: auto;
      margin: 0 auto;
    }
  `}
`

const profileImageStyle = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  ${down('medium')} {
    width: 150px;
    height: 150px;
  }
`

const ProfileImage = styled.img.attrs({ alt: 'Profile' })`
  ${profileImageStyle}
`

const StyledNoUserImage = styled(NoUserImage)`
  ${profileImageStyle}
`

const StyledFaChevronDown = styled(FaChevronDown)`
  ${down('medium')} {
    ${({ $isMenuShown }) =>
      $isMenuShown &&
      css`
        visibility: hidden;
      `}
  }
`

const DisplayName = styled.span`
  ${down('medium')} {
    font-size: ${({ theme }) => theme.fontSizes.large};
    color: ${({ theme }) => theme.colors.neutral.gray1200};
  }
`

const ProfileMenuList = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 100%;
    right: ${theme.spacers.normal};
    width: 300px;
    background-color: ${theme.colors.neutral.white};
    border-radius: ${({ theme }) => theme.round.medium};
    box-shadow: 0 0 5px 1px ${theme.colors.neutral.gray900};
    z-index: ${theme.zIndex.dropdown};

    &::before {
      position: absolute;
      content: '';
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent ${theme.colors.neutral.white}
        transparent;
      filter: drop-shadow(0px -2px 1px ${theme.colors.neutral.gray700});
    }

    &.profile-menu {
      &-enter {
        opacity: 0;
        transform: translateY(10%) scale(0.8);
      }

      &-enter-active {
        opacity: 1;
        transform: translateY(0) scale(1);
        transition: opacity 200ms, transform 200ms;
      }

      &-exit {
        opacity: 1;
        transform: translateY(0) scale(1);
      }

      &-exit-active {
        opacity: 0;
        transform: translateY(10%) scale(0.8);
        transition: opacity 200ms, transform 200ms;
      }
    }

    ${down('medium')} {
      left: 50%;
      transform: translateX(-50%);

      &.profile-menu {
        &-enter {
          opacity: 0;
          transform: translate(-50%, 10%) scale(0.8);
        }

        &-enter-active {
          opacity: 1;
          transform: translate(-50%, 0) scale(1);
          transition: opacity 200ms, transform 200ms;
        }

        &-exit {
          opacity: 1;
          transform: translate(-50%, 0) scale(1);
        }

        &-exit-active {
          opacity: 0;
          transform: translate(-50%, 10%) scale(0.8);
          transition: opacity 200ms, transform 200ms;
        }
      }
    }
  `}
`

const ProfileMenuListWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.white};
    border-radius: ${theme.round.medium};
    overflow: hidden;
  `}
`

const ProfileDetails = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-areas:
      'image name name'
      'image edit logout';
    gap: ${theme.spacers.xxsmall};
    padding: ${theme.spacers.normal};
  `}
`

const profileDetailsImage = css`
  grid-area: image;
  justify-self: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`
const ProfileDetailsNoImage = styled(NoUserImage)`
  ${profileDetailsImage}
`

const ProfileDetailsImage = styled.img.attrs({ alt: 'Profile' })`
  ${profileDetailsImage}
`

const ProfileDetailsName = styled.span`
  grid-area: name;
  color: ${({ theme }) => theme.colors.neutral.gray1200};
`

const profileDetailsLinkStyle = css`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray800};
    text-decoration: underline;
    font-size: ${theme.fontSizes.small};
    cursor: pointer;
    transition: color 0.25s;

    &:hover {
      color: ${theme.colors.main.primary};
    }

    ${down('medium')} {
      font-size: ${theme.fontSizes.normal};
    }
  `}
`

const ProfileDetailsEdit = styled.a`
  grid-area: edit;

  ${profileDetailsLinkStyle}
`

const ProfileDetailsLogout = styled.a.attrs({ href: '#' })`
  grid-area: logout;

  ${profileDetailsLinkStyle}
`

const ProfileMenuItem = styled.a`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    color: ${theme.colors.neutral.gray1000};
    padding: ${theme.spacers.normal};
    text-align: center;
    border-top: 1px solid ${theme.colors.neutral.gray300};
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      color: ${theme.colors.neutral.white};
      background-color: ${theme.colors.main.secondary};
    }
  `}
`

function ProfileMenu({ setIsRightCollapseMenu }) {
  const { user } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const [isMenuShown, setIsMenuShown] = useState(false)
  const { profileImage, isProfileImageLoaded } = useProfileImage()
  const displayName = user?.displayName || 'สมาชิก Babel Coder'

  const toggleMenu = useCallback(() => setIsMenuShown(!isMenuShown), [
    isMenuShown,
    setIsMenuShown,
  ])

  const logout = useCallback(async () => {
    const firebaseAuth = await getFirebaseAuth()

    await firebaseAuth.signOut()
    setIsRightCollapseMenu(true)
    dispatch({ type: actions.logout })
    dispatch({
      type: actions.showPopupMessage,
      popupMessage: {
        type: 'success',
        title: 'ดำเนินการสำเร็จ',
        message: 'คุณได้ออกจากระบบเป็นที่เรียบร้อยแล้ว',
      },
    })
  }, [dispatch, setIsRightCollapseMenu])

  const navigateTo = useCallback(
    (event) => {
      event.preventDefault()

      const href = event.target.getAttribute('href')

      setIsRightCollapseMenu(true)
      navigate(href)
    },
    [setIsRightCollapseMenu]
  )

  return (
    <Wrapper>
      <Display onClick={toggleMenu}>
        {profileImage ? (
          <ProfileImage src={profileImage}></ProfileImage>
        ) : (
          isProfileImageLoaded && <StyledNoUserImage></StyledNoUserImage>
        )}
        <DisplayName>{displayName}</DisplayName>
        <StyledFaChevronDown $isMenuShown={isMenuShown}></StyledFaChevronDown>
        <CSSTransition
          in={isMenuShown}
          timeout={300}
          classNames="profile-menu"
          unmountOnExit
        >
          <ProfileMenuList>
            <ProfileMenuListWrapper>
              <ProfileDetails>
                {profileImage ? (
                  <ProfileDetailsImage src={profileImage}></ProfileDetailsImage>
                ) : (
                  isProfileImageLoaded && (
                    <ProfileDetailsNoImage></ProfileDetailsNoImage>
                  )
                )}
                <ProfileDetailsName>{displayName}</ProfileDetailsName>
                <ProfileDetailsEdit href="/auth/profile" onClick={navigateTo}>
                  แก้ไข
                </ProfileDetailsEdit>
                <ProfileDetailsLogout onClick={logout}>
                  ออกจากระบบ
                </ProfileDetailsLogout>
              </ProfileDetails>
              <ProfileMenuItem href="/dashboard/courses" onClick={navigateTo}>
                คอร์สของฉัน
              </ProfileMenuItem>
            </ProfileMenuListWrapper>
          </ProfileMenuList>
        </CSSTransition>
      </Display>
    </Wrapper>
  )
}

export default ProfileMenu
