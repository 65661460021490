import React, { useContext } from 'react'

import FixedHeader from './header/FixedHeader'
import DefaultHeader from './header/DefaultHeader'
import DisabledShadowOnTopHeader from './header/DisabledShadowOnTopHeader'
import { LayoutContext } from './Layout'

const HEADER_TYPES = {
  fixed: FixedHeader,
  default: DefaultHeader,
  disabledShadowOnTopHeader: DisabledShadowOnTopHeader,
}

export default () => {
  const { headerType } = useContext(LayoutContext)
  const Component = HEADER_TYPES[headerType]

  return <Component></Component>
}
