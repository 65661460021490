import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import ToggableSidebar from '@babelcoder/gatsby-theme-base/src/components/ToggableSidebar'
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import UnitItem from './UnitItem'

const CourseLink = styled(Link)`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.neutral.gray1500};
    background-color: ${theme.colors.neutral.gray600};
    padding: ${theme.spacers.small};
    transition: color 0.25s, background-color 0.25s;

    &:hover {
      color: ${theme.colors.neutral.white};
      background-color: ${theme.colors.main.primary};
    }
  `}
`

function LessonsTable({
  course: { slug: courseSlug, name, units },
  lesson: currentLesson,
}) {
  const { getCoursePath } = useCoursesPath()

  return (
    <ToggableSidebar>
      <CourseLink to={getCoursePath(courseSlug)}>{name}</CourseLink>
      {units.map(({ title, lessons }) => (
        <UnitItem
          key={title}
          title={title}
          courseSlug={courseSlug}
          currentLesson={currentLesson}
          lessons={lessons}
        ></UnitItem>
      ))}
    </ToggableSidebar>
  )
}

export default LessonsTable
