import React from 'react'
import styled, { css } from 'styled-components'
import { FaTimes } from 'react-icons/fa'

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  ${({ theme }) => css`
    &:before {
      content: '';
      transition: background-color 0.25s ease-in-out;
    }

    &:hover {
      &:before {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: ${theme.colors.gray300};
        opacity: 0.5;
      }
    }
  `}
`

const StyledCloseButton = styled(FaTimes)``

const CloseButton = (props) => (
  <Wrapper {...props}>
    <StyledCloseButton></StyledCloseButton>
  </Wrapper>
)

export default CloseButton
