import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { up } from 'styled-breakpoints';
import Portal from './Portal';
import CloseButton from './CloseButton';

const smallSizeAnimation = keyframes`
  from {
    transform:  scale(0.5);
  }

  to {
    transform:  scale(1);
  }
`;

const largeSizeAnimation = keyframes`
  from {
    transform:  translate(-50%, -50%) scale(0.2);
  }

  to {
    transform:  translate(-50%, -50%) scale(1);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.popup};
`;

const Overlay = styled.div`
  ${({ theme }) => css`
    ${up('small')} {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${theme.colors.neutral.black};
      opacity: 0.5;
    }
  `}
`;

const StyledModal = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: ${theme.spacers.normal};
    background: ${theme.colors.neutral.white};
    will-change: transform;
    animation: ${smallSizeAnimation} 0.15s ease-in;
    animation-fill-mode: forwards;
    overscroll-behavior: contain;
    overflow-y: scroll;

    ${up('small')} {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
      box-shadow: ${`0 2px 8px ${theme.colors.neutral.gray600}`};
      border-radius: ${theme.round.normal};
      animation: ${largeSizeAnimation} 0.15s ease-in;
      animation-fill-mode: forwards;
      max-height: 70vh;
    }
  `}
`;

const StyledCloseButton = styled(CloseButton)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacers.normal};
    right: ${theme.spacers.normal};
    color: ${theme.colors.gray600};
  `}
`;

const Modal = ({ onClose, children }) => {
  return (
    <Portal>
      <Wrapper>
        <Overlay></Overlay>
        <StyledModal>
          {children}
          <StyledCloseButton onClick={onClose}></StyledCloseButton>
        </StyledModal>
      </Wrapper>
    </Portal>
  );
};

export default Modal;
