import { useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import getDefaultOptions from '../utils/default-options';
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash';

function useCoursesPath() {
  const { sitePlugin } = useStaticQuery(graphql`
    query {
      sitePlugin(name: { eq: "@babelcoder/gatsby-theme-courses" }) {
        pluginOptions {
          basePath
        }
      }
    }
  `);
  const { basePath } = getDefaultOptions(sitePlugin.pluginOptions);
  const path = toSingleSlash(`${basePath}`);

  const getCoursePath = useCallback(
    (slug) => toSingleSlash(`${path}/${slug}/`),
    [path]
  );

  const getCourseOverviewPath = useCallback(
    (slug) => `${toSingleSlash(`${getCoursePath(slug)}/overview`)}`,
    [getCoursePath]
  );

  const getCourseTermsAndConditionsPath = useCallback(
    (slug) => `${toSingleSlash(`${getCoursePath(slug)}/terms-and-conditions`)}`,
    [getCoursePath]
  );

  const getCourseRegisterPath = useCallback(
    (slug) => `${toSingleSlash(`${getCoursePath(slug)}/register`)}`,
    [getCoursePath]
  );

  const getCourseQuotationPath = useCallback(
    (slug) => `${toSingleSlash(`${getCoursePath(slug)}/quotation`)}`,
    [getCoursePath]
  );

  const getLessonPath = useCallback(
    (courseSlug, lessonSlug) =>
      `${toSingleSlash(`${getCoursePath(courseSlug)}/lessons/${lessonSlug}`)}`,
    [getCoursePath]
  );

  return {
    basePath,
    path,
    getCoursePath,
    getCourseOverviewPath,
    getCourseTermsAndConditionsPath,
    getCourseRegisterPath,
    getCourseQuotationPath,
    getLessonPath,
  };
}

export default useCoursesPath;
