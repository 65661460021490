import { css } from 'styled-components'

import font from './font'

export default css`
  /* ${font}; */

  .d-none {
    display: none;
  }
`
