import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { CookiesConsentContext } from './CookiesConsentProvider';
import { up } from 'styled-breakpoints';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  padding: ${({ theme }) => theme.spacers.normal};
  color: #fff;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.7);

  ${up('medium')} {
    left: ${({ theme }) => theme.spacers.normal};
    right: ${({ theme }) => theme.spacers.normal};
    bottom: ${({ theme }) => theme.spacers.normal};
    border-radius: 10px;
  }

  ${up('xlarge')} {
    left: 0;
    right: 0;
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 1200px;
    min-width: 1100px;
  }
`;

const Title = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.heading};
`;

const Content = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.base.medium};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.main.secondary};
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const AcceptButton = styled.button`
  ${({ theme }) => css`
    display: block;
    padding: ${theme.spacers.xxsmall} ${theme.spacers.small};
    color: ${theme.colors.neutral.white};
    border-radius: 2rem;
    border: none;
    outline: none;
    transition: background-color 0.25s;
    background-color: ${theme.colors.main.primary};
    cursor: pointer;
  `}
`;

const SettingLink = styled.button`
  display: block;
  border: none;
  outline: none;
  color: #fff;
  text-decoration: underline;
  background-color: transparent;
  margin-left: ${({ theme }) => theme.spacers.normal};
  cursor: pointer;
`;

const CookiesConsentDialog = () => {
  const { openSetting, acceptAll, isSet, isLoaded } = useContext(
    CookiesConsentContext
  );

  if (!isLoaded) return null;
  if (isSet) return null;

  return (
    <Wrapper>
      <Title>เว็บไซต์นี้มีการจัดเก็บข้อมูล</Title>
      <Content>
        เราจัดเก็บข้อมูลเพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้งานเว็บไซต์
        เราสามารถนำเสนอสิ่งที่คุณสนใจเป็นพิเศษเมื่อคุณกด "ยอมรับทั้งหมด"
        ทั้งนี้คุณสามารถเลือกตั้งค่าความยินยอมในการใช้งานผ่าน "การตั้งค่า"
        หากคุณไม่ปรับเปลี่ยนการตั้งค่าเราเข้าใจว่าคุณให้ความยินยอมในการจัดเก็บข้อมูลทั้งหมดแล้ว{' '}
        <StyledLink to="/privacy-policy">อ่านนโยบายความเป็นส่วนตัว</StyledLink>
      </Content>
      <Footer>
        <AcceptButton onClick={acceptAll}>ยอมรับทั้งหมด</AcceptButton>
        <SettingLink onClick={openSetting}>การตั้งค่า</SettingLink>
      </Footer>
    </Wrapper>
  );
};

export default CookiesConsentDialog;
