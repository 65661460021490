import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'

import { HeaderContext } from '@babelcoder/gatsby-theme-base/src/components/header/HeaderProvider'
import BaseHeader from './BaseHeader'

const StyledHeader = styled(BaseHeader)`
  box-shadow: 0 2px 8px ${({ theme }) => theme.colors.neutral.gray300};
`

function FixedHeader() {
  const { setIsHidden } = useContext(HeaderContext)

  useEffect(() => {
    setIsHidden(false)
  }, [setIsHidden])

  return <StyledHeader></StyledHeader>
}

export default FixedHeader
