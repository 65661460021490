function getDefaultOptions(options = {}) {
  return {
    basePath: '/',
    contentPath: 'data',
    instructor: {
      name: null,
      desc: null,
    },
    ...options,
  }
}

module.exports = getDefaultOptions
