import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

import { HeaderContext } from './header/HeaderProvider'
import CollapseButton from './header/CollapseButton'
import LogoImage from './header/LogoImage'

const Wrapper = styled.aside`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 300px;
    background-color: ${theme.colors.neutral.gray200};
    z-index: ${theme.zIndex.sidebar};
    overflow-y: scroll;

    &.lessons-table {
      &-enter {
        opacity: 0;
        transform: translateX(-300px);
      }

      &-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 250ms, transform 250ms;
      }

      &-exit {
        opacity: 1;
        transform: translateX(0);
      }

      &-exit-active {
        transform: translateX(-300px);
        transition: opacity 250ms, transform 250ms;
      }
    }
  `}
`

const Navbar = styled.div`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    background-color: ${theme.colors.neutral.white};
    box-shadow: -8px 0 8px ${theme.colors.neutral.gray300};
    height: ${theme.layout.height.header}px;
  `}
`

const BannerNav = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral.gray300};
  width: 300px;
`

const Logo = styled(Link).attrs({ to: '/' })`
  ${({ theme, $isRightCollapseMenu }) => css`
    display: flex;
    align-items: center;
    padding: 0 ${theme.spacers.normal};

    ${!$isRightCollapseMenu &&
    css`
      display: none;
    `}
  `}
`

const LogoText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.main.primary};

    & + & {
      margin-left: calc(${theme.spacers.normal} / 2);
      color: ${theme.colors.neutral.gray1200};
    }
  `}
`

function ToggableSidebar({ children }) {
  const { isLeftCollapseMenu, isRightCollapseMenu } = useContext(HeaderContext)

  return (
    <CSSTransition
      in={!isLeftCollapseMenu}
      timeout={300}
      classNames="lessons-table"
      unmountOnExit
    >
      <Wrapper>
        <Navbar>
          <BannerNav>
            <CollapseButton></CollapseButton>
            <Logo $isRightCollapseMenu={isRightCollapseMenu}>
              <LogoImage></LogoImage>
              <LogoText>BABEL</LogoText>
              <LogoText>CODER</LogoText>
            </Logo>
          </BannerNav>
        </Navbar>
        {children}
      </Wrapper>
    </CSSTransition>
  )
}

export default ToggableSidebar
