import React from 'react'
import styled, { css } from 'styled-components'
import { up, down } from 'styled-breakpoints'

import NavItem from './NavItem'

const SecondaryNavWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    ${down('medium')} {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: ${theme.layout.height.header}px;
      z-index: ${theme.zIndex.topmost};
    }

    ${up('large')} {
      margin-left: auto;
    }
  `}
`

function SecondaryNav() {
  return (
    <SecondaryNavWrapper>
      <NavItem secondary={true} to="/auth/sign-in">
        เข้าสู่ระบบ
      </NavItem>
      <NavItem secondary={true} $highlight={true} to="/auth/sign-up">
        สมัครสมาชิก
      </NavItem>
    </SecondaryNavWrapper>
  )
}

export default SecondaryNav
