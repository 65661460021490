import React, { useContext } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { up, down } from 'styled-breakpoints'

import { HeaderContext } from '@babelcoder/gatsby-theme-base/src/components/header/HeaderProvider'
import LogoImage from './LogoImage'

const Wrapper = styled(Link).attrs({ to: '/' })`
  ${({ theme, $isRightCollapseMenu }) => css`
    display: flex;
    align-items: center;

    ${!$isRightCollapseMenu &&
    css`
      display: none;
    `}

    ${down('medium')} {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ${up('large')} {
      padding: 0 ${theme.spacers.normal};
    }
  `}
`

const LogoText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.heading};
    color: ${theme.colors.main.primary};

    & + & {
      margin-left: calc(${theme.spacers.normal} / 2);
      color: ${theme.colors.neutral.gray1200};
    }
  `}
`

function Logo() {
  const { isRightCollapseMenu } = useContext(HeaderContext)

  return (
    <Wrapper $isRightCollapseMenu={isRightCollapseMenu}>
      <LogoImage></LogoImage>
      <LogoText>BABEL</LogoText>
      <LogoText>CODER</LogoText>
    </Wrapper>
  )
}

export default Logo
