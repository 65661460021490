import { css } from 'styled-components'

export default css`
  @font-face {
    font-family: 'Fira Code';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/FiraCode-Light.ttf);
  }
  @font-face {
    font-family: 'Fira Code';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(FiraCode-Regular.ttf);
  }
  @font-face {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Prompt'), local('Prompt-Regular'),
      url(/fonts/Prompt-Regular.ttf);
  }
  @font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Sarabun ExtraLight'), local('Sarabun-ExtraLight'),
      url(/fonts/Sarabun-ExtraLight.ttf);
  }
`
