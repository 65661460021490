import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { up, down } from 'styled-breakpoints'

import { HeaderContext } from '@babelcoder/gatsby-theme-base/src/components/header/HeaderProvider'
import BannerNav from './BannerNav'

const StyledMenu = styled.nav`
  ${({ theme, isRightCollapseMenu }) => css`
    display: flex;
    position: relative;
    height: ${theme.layout.height.header}px;

    ${down('medium')} {
      height: 100%;
      ${isRightCollapseMenu && `align-items: center`}
    }

    ${up('large')} {
      align-items: center;
    }
  `}
`

function Menu({ children }) {
  const { isRightCollapseMenu } = useContext(HeaderContext)

  return (
    <StyledMenu isRightCollapseMenu={isRightCollapseMenu}>
      <BannerNav></BannerNav>
      {children}
    </StyledMenu>
  )
}

export default Menu
