import React, { createContext, useMemo, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const CookiesConsentContext = createContext();

const valueToBool = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;

  return undefined;
};

const CookiesConsentProvider = ({ children }) => {
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const [consent, setConsent] = useState({
    base: true,
    stat: true,
    ads: true,
  });
  const openSetting = () => setIsDialogShown(true);
  const closeSetting = () => setIsDialogShown(false);
  const changeConsent = (key, value) =>
    setConsent({ ...consent, [key]: value });
  const handleStat = (value) => {
    Cookies.set('_c-ga', value);
  };
  const handleAds = (value) => {
    Cookies.set('_c-fp', value);
  };
  const acceptAll = () => {
    localStorage.setItem('consent', true);
    setConsent({
      base: true,
      stat: true,
      ads: true,
    });
    handleStat(true);
    handleAds(true);
    setIsSet(true);
  };
  const acceptMyChoices = () => {
    localStorage.setItem('consent', true);
    handleStat(consent.stat);
    handleAds(consent.ads);
    setIsSet(true);
  };
  const value = useMemo(
    () => ({
      isDialogShown,
      openSetting,
      closeSetting,
      consent,
      changeConsent,
      acceptAll,
      acceptMyChoices,
      isSet,
      isLoaded,
    }),
    [
      isDialogShown,
      openSetting,
      closeSetting,
      consent,
      changeConsent,
      acceptAll,
      acceptMyChoices,
      isSet,
      isLoaded,
    ]
  );

  useEffect(() => {
    setIsSet(Boolean(localStorage.getItem('consent')));
    setIsLoaded(true);
    const cookies = Cookies.get();
    setConsent({
      base: true,
      stat: valueToBool(cookies['_c-ga']),
      ads: valueToBool(cookies['_c-fp']),
    });
  }, [setIsSet, setIsLoaded]);

  return (
    <CookiesConsentContext.Provider value={value}>
      {children}
    </CookiesConsentContext.Provider>
  );
};

export default CookiesConsentProvider;
