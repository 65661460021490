import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import { HeaderContext } from '@babelcoder/gatsby-theme-base/src/components/header/HeaderProvider'

const Wrapper = styled.div`
  ${({ theme, isOpened }) => css`
    margin: 0 ${theme.spacers.normal};
    margin-left: auto;

    ${isOpened &&
    css`
      position: absolute;
      right: 0;
    `}

    ${up('large')} {
      display: none;
    }
  `}
`

const HamburgerMenuWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => `${theme.layout.height.header}px`};
  cursor: pointer;
  transition: all 0.5s ease-in-out;
`
const HamburgerMenu = styled.div`
  ${({ theme, isOpened }) => css`
    width: 25px;
    height: 3px;
    background-color: ${theme.colors.neutral.gray800};
    border-radius: 5px;
    transition: all 0.25s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 3px;
      background-color: ${theme.colors.neutral.gray800};
      border-radius: 5px;
      transition: all 0.25s ease-in-out;
    }

    &::before {
      transform: translateY(-8px);
    }

    &::after {
      transform: translateY(8px);
    }

    ${isOpened &&
    css`
      transform: translateX(-25px);
      background: transparent;
      box-shadow: none;

      &::before,
      &::after {
        background: ${theme.colors.neutral.white};
      }

      &::before {
        transform: rotate(45deg) translate(18px, -18px);
      }

      &::after {
        transform: rotate(-45deg) translate(18px, 18px);
      }
    `}
  `}
`

function RightCollapseMenu() {
  const { isRightCollapseMenu, toggleRightCollapseMenu } = useContext(
    HeaderContext
  )
  const isOpen = !isRightCollapseMenu

  return (
    <Wrapper isOpened={isOpen} onClick={toggleRightCollapseMenu}>
      <HamburgerMenuWrapper>
        <HamburgerMenu isOpened={isOpen}></HamburgerMenu>
      </HamburgerMenuWrapper>
    </Wrapper>
  )
}

export default RightCollapseMenu
