module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-76498481-1","cookieName":"_c-ga","anonymize":true,"allowAdFeatures":false},"facebookPixel":{"pixelId":"448184676227297","cookieName":"_c-fp"},"environments":["production","development"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#37BC9B","showSpinner":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"withWebp":true}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff"]}},{"resolve":"gatsby-remark-katex","options":{"strict":"ignore"}}]},
    },{
      plugin: require('../../gatsby-theme-base/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-courses/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/courses","baseOnlinePath":"/online-courses","baseEnterprisePath":"/enterprise-courses","baseClassRoomPath":"/classroom-courses","contentPath":"content/courses","instructor":{"name":"Nuttavut Thongjor","desc":"นักพัฒนาซอฟต์แวร์ประสบการณ์กว่า 10 ปี ผู้ก่อตั้ง Babel Coder วิทยากรอบรมการพัฒนาซอฟต์แวร์และผู้ให้คำปรึกษาในบริษัทชั้นนำ"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
