import { lighten, darken, modularScale } from 'polished'

// Name that Color: http://chir.ag/projects/name-that-color
const colorNames = {
  keppel: '#37BC9B',
  java: '#17D8A9',
  cinnabar: '#EC4C47',
  sun: '#FFAA15',
  pictonBlue: '#55ACEE',
  shamrock: '#39CF94',
}

const mainColors = {
  primary: colorNames.keppel,
  secondary: colorNames.java,
  success: colorNames.shamrock,
  warning: colorNames.sun,
  info: colorNames.pictonBlue,
  danger: colorNames.cinnabar,
}

const neutralColors = {
  white: '#fff',
  gray100: '#FCFDFD',
  gray200: '#F4F6F5',
  gray300: '#EEF1F0',
  gray400: '#E6EAE9',
  gray500: '#DEE3E2',
  gray600: '#BFC4C3',
  gray700: '#AFB6B4',
  gray800: '#919795',
  gray900: '#7C8381',
  gray1000: '#545454',
  gray1100: '#646464',
  gray1200: '#434A54',
  gray1500: '#333333',
  gray2000: '#1e1e1e',
  black: '#000',
  transparent: 'transparent',
}

const accentColors = {
  primaryLighten15: lighten(0.15, mainColors.primary),
  primaryLighten30: lighten(0.3, mainColors.primary),
  primaryLighten45: lighten(0.45, mainColors.primary),
  primaryLighten60: lighten(0.6, mainColors.primary),
  primaryDarken15: darken(0.15, mainColors.primary),
  primaryDarken30: darken(0.3, mainColors.primary),
  secondaryLighten15: lighten(0.15, mainColors.secondary),
  secondaryLighten30: lighten(0.3, mainColors.secondary),
  secondaryDarken15: darken(0.15, mainColors.secondary),
  secondaryDarken30: darken(0.3, mainColors.secondary),
  successLighten15: lighten(0.15, mainColors.success),
  successLighten30: lighten(0.3, mainColors.success),
  successDarken15: darken(0.15, mainColors.success),
  successDarken30: darken(0.3, mainColors.success),
  warningLighten15: lighten(0.15, mainColors.warning),
  warningLighten30: lighten(0.3, mainColors.warning),
  warningDarken15: darken(0.15, mainColors.warning),
  warningDarken30: darken(0.3, mainColors.warning),
  infoLighten15: lighten(0.15, mainColors.info),
  infoLighten30: lighten(0.3, mainColors.info),
  infoDarken15: darken(0.15, mainColors.info),
  infoDarken30: darken(0.3, mainColors.info),
  dangerLighten15: lighten(0.15, mainColors.danger),
  dangerLighten30: lighten(0.3, mainColors.danger),
  dangerDarken15: darken(0.15, mainColors.danger),
  dangerDarken30: darken(0.3, mainColors.danger),
}

const colors = {
  main: mainColors,
  accent: accentColors,
  neutral: neutralColors,
}

const codeColors = {
  comment: '#6a737d',
  punctuation: '#5e6687',
  number: '#d73a49',
  property: '#c08b30',
  tag: '#3d8fd1',
  string: '#032f62',
  selector: '#6679cc',
  attr: '#c76b29',
  entity: '#22a2c9',
  keyword: '#d73a49',
  function: '#6f42c1',
  statement: '#22a2c9',
  placeholder: '#3d8fd1',
  inserted: '#202746',
  important: '#c94922',
}

const fontFamily = {
  heading: "'Prompt', sans-serif",
  normal: "'Sarabun', sans-serif",
  code: "'Fira Code', monospace",
}

const fontSizeBase = '1rem'
const modularScaleRatio = {
  fontSize: 1.25,
  spacer: 1.5,
  border: 1.5,
  radius: 0.25,
}

const spacerBase = '1rem'
const borderSizeBase = '1px'
const borderRadiusBase = '0.25rem'

const zIndex = {
  hidden: -1,
  ribbon: 10,
  navbar: 50,
  overlay: 100,
  sidebar: 200,
  dropdown: 300,
  popup: 500,
  topmost: 600,
}

export const breakpoints = {
  xsmall: 375,
  small: 576,
  medium: 768,
  large: 992,
  xlarge: 1200,
}

const layout = {
  height: {
    header: 80,
    flashMessage: 50,
  },
}

export default {
  breakpoints: Object.entries(breakpoints).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: `${v}px` }),
    {}
  ),
  fontFamily,
  fontSizes: {
    base: {
      small: '16px',
      medium: '16px',
      large: '18px',
    },
    xxsmall: modularScale(-3, fontSizeBase, modularScaleRatio.fontSize),
    xsmall: modularScale(-2, fontSizeBase, modularScaleRatio.fontSize),
    small: modularScale(-1, fontSizeBase, modularScaleRatio.fontSize),
    normal: fontSizeBase,
    medium: modularScale(1, fontSizeBase, modularScaleRatio.fontSize),
    large: modularScale(2, fontSizeBase, modularScaleRatio.fontSize),
    xlarge: modularScale(3, fontSizeBase, modularScaleRatio.fontSize),
    xxlarge: modularScale(4, fontSizeBase, modularScaleRatio.fontSize),
  },
  spacers: {
    base: spacerBase,
    none: 0,
    xxsmall: modularScale(-3, spacerBase, modularScaleRatio.spacer),
    xsmall: modularScale(-2, spacerBase, modularScaleRatio.spacer),
    small: modularScale(-1, spacerBase, modularScaleRatio.spacer),
    normal: spacerBase,
    medium: modularScale(1, spacerBase, modularScaleRatio.spacer),
    large: modularScale(2, spacerBase, modularScaleRatio.spacer),
    xlarge: modularScale(3, spacerBase, modularScaleRatio.spacer),
    auto: 'auto',
  },
  round: {
    base: borderRadiusBase,
    none: 0,
    xsmall: modularScale(-2, borderRadiusBase, modularScaleRatio.spacer),
    small: modularScale(-1, borderRadiusBase, modularScaleRatio.spacer),
    normal: borderRadiusBase,
    medium: modularScale(1, borderRadiusBase, modularScaleRatio.spacer),
    large: modularScale(2, borderRadiusBase, modularScaleRatio.spacer),
    xlarge: modularScale(3, borderRadiusBase, modularScaleRatio.spacer),
  },
  borderSizes: {
    none: '0',
    xsmall: borderSizeBase,
    small: modularScale(1, borderSizeBase, modularScaleRatio.border),
    normal: modularScale(2, borderSizeBase, modularScaleRatio.border),
    medium: modularScale(3, borderSizeBase, modularScaleRatio.border),
    large: modularScale(4, borderSizeBase, modularScaleRatio.border),
    xlarge: modularScale(5, borderSizeBase, modularScaleRatio.border),
  },
  colors: {
    ...colors,
    ...colors.main,
    ...colors.neutral,
    ...colors.accent,
    code: codeColors,
  },
  zIndex,
  layout,
}
