import { useCallback, useEffect } from 'react';
import {
  getFirebaseAuth,
  getFirebaseFirestore,
} from '@babelcoder/gatsby-plugin-firebase';

import {
  useGlobalDispatch,
  useGlobalState,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer';

function AuthChecker() {
  const dispatch = useGlobalDispatch();
  const { user } = useGlobalState();

  const closeFlashMessage = useCallback(
    () => dispatch({ type: actions.clearFlashMessage }),
    [dispatch]
  );

  const sendEmailVerification = useCallback(async () => {
    try {
      await user.sendEmailVerification();
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'success',
          title: 'การดำเนินการสำเร็จ',
          message: 'เราได้จัดส่งการยืนยันอีเมล์ผู้ใช้งานเรียบร้อยแล้ว',
        },
      });
      closeFlashMessage();
    } catch (error) {
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'danger',
          title: 'เกิดข้อผิดพลาด',
          message: 'การจัดส่งการยืนยันอีเมล์ไม่สำเร็จ โปรดลองอีกครั้ง',
        },
      });
    }
  }, [user, dispatch, closeFlashMessage]);

  const checkEmailVerified = useCallback(() => {
    if (user && !user.emailVerified) {
      dispatch({
        type: actions.showFlashMessage,
        flashMessage: {
          message: 'การลงทะเบียนของคุณยังไม่เสร็จสิ้น โปรดยืนยันอีเมล์',
          type: 'warning',
          onAction: sendEmailVerification,
          actionText: 'ยืนยันอีเมล์',
        },
      });
    } else {
      dispatch({
        type: actions.showFlashMessage,
        flashMessage: null,
      });
    }
  }, [user, dispatch, sendEmailVerification]);

  const watchUserChanged = useCallback(async () => {
    const firebaseAuth = await getFirebaseAuth();

    firebaseAuth.onAuthStateChanged((user) => {
      checkEmailVerified();
      dispatch({ type: actions.setCurrentUser, user });
    });
  }, [checkEmailVerified, dispatch]);

  const getFullName = useCallback(async () => {
    if (!user) return;

    const firestore = await getFirebaseFirestore();
    const snapshot = await firestore.doc(`users/${user.uid}`).get();
    dispatch({ type: actions.setFullName, fullName: snapshot.data().fullName });
  }, [user]);

  useEffect(() => {
    watchUserChanged();
    getFullName();
  }, [watchUserChanged]);

  return null;
}

export default AuthChecker;
